
import { Component, Vue, Watch } from 'vue-property-decorator';
import SideDescriptionArea from "@/components/SideDescriptionArea.vue";
import Checkboxes from '@/components/form/Checkbox.vue';
import SocialIcons from "@/components/SocialIcons.vue";

@Component({
  components: {
    SocialIcons,
    SideDescriptionArea,
    Checkboxes
  },
})

export default class Home extends Vue {

  firstname = '';
  lastname = '';
  email = '';
  cf_piva = '';
  privacy = false;

  errorFields: string[] = [];

  startSurvey() {
      if( !this.validate() )
          return;

    this.$store.dispatch('startSurvey', {
      firstname: this.firstname,
      lastname: this.lastname,
      email: this.email,
      cfPiva: this.cf_piva
    })
  }

  // Orrenda validazione, but It Works
  validate() {
      if(!this.firstname)
          this.errorFields.push('firstname');
      else
          this.errorFields = this.errorFields.filter(el => !(el === 'firstname'))

      if(!this.lastname)
          this.errorFields.push('lastname');
      else
          this.errorFields = this.errorFields.filter(el => !(el === 'lastname'))

      if(!this.email)
          this.errorFields.push('email');
      else
          this.errorFields = this.errorFields.filter(el => !(el === 'email'))

      if(!this.cf_piva)
          this.errorFields.push('cf_piva');
      else
          this.errorFields = this.errorFields.filter(el => !(el === 'cf_piva'))

      if(!this.privacy)
          this.errorFields.push('privacy');
      else
          this.errorFields = this.errorFields.filter(el => !(el === 'privacy'))

      return !this.errorFields.length;
  }

  isError(field: string) {
      return this.errorFields.includes(field)
  }

  async mounted() {
      await this.$store.dispatch('fetchSurvey');
      await this.$store.dispatch('fetchGoals');
      const modules = this.$store.getters.getAllModules[0];
      await this.$store.dispatch('setCurrentModule', modules.id);
      await this.$store.dispatch('setCurrentQuestion', modules.questions[0].id)

  }

  @Watch('questions', { deep: true } )
  onCheckboxesChanged(val: Array<object>) {
    console.log(val)
  }

}
