import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

import router from '../router';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    participantId: 0,
    participant: {
      firstname: '',
      lastname: '',
      cfPiva: '',
      email: ''
    },
    goals: [
      {
        id: 0,
        title: "",
        description: "",
      }
    ],
    extraFields: {},
    goalsSelected: [],
    surveyIsDone: false,
    currentModule: 0,
    currentQuestion: 0,
    answers: [],
    survey: {
      "id": 1,
      "title": "Questionario qualitativo",
      "description": null,
      "start_message": null,
      "complete_message": null,
      "opens_at": "2020-10-01T08:00:00.000000Z",
      "closes_at": "2021-10-01T08:00:00.000000Z",
      "current_revision": {
        "id": 1,
        "version": 1,
        "modules": [
          {
            "id": 1,
            "title": "Anagrafica impresa",
            "description": null,
            "picture_url": null,
            "questions": [
              {
                "id": 1,
                "text": "Denominazione Ragione sociale",
                "attachment_url": null,
                "picture_url": null,
                "order": 0,
                "answers": [

                ],
                "type": {
                  "id": 3,
                  "slug": "OPEN_TEXT_SINGLE"
                }
              },
              {
                "id": 2,
                "text": "Forma giuridica",
                "attachment_url": null,
                "picture_url": null,
                "order": 1,
                "answers": [
                  {
                    "id": 2,
                    "text": "Societ\u00e0 a Responsabilit\u00e0 Limitata (SRL)",
                    "feedback": null,
                    "order": 2
                  },
                  {
                    "id": 1,
                    "text": "Societ\u00e0 in Nome Collettivo (SNC)",
                    "feedback": null,
                    "order": 1
                  },
                  {
                    "id": 3,
                    "text": "Societ\u00e0 per Azioni (SPA)",
                    "feedback": null,
                    "order": 3
                  }
                ],
                "type": {
                  "id": 6,
                  "slug": "LIST_SINGLE"
                }
              },
              {
                "id": 3,
                "text": "Numero dipendenti",
                "attachment_url": null,
                "picture_url": null,
                "order": 2,
                "answers": [

                ],
                "type": {
                  "id": 1,
                  "slug": "OPEN_NUMERIC"
                }
              }
            ],
            "order": null
          },
          {
            "id": 2,
            "title": "Analisi settore attivit\u00e0",
            "description": null,
            "picture_url": null,
            "questions": [
              {
                "id": 4,
                "text": "Da quanti anni l'azienda opera nel settore?",
                "attachment_url": null,
                "picture_url": null,
                "order": 3,
                "answers": [
                  {
                    "id": 6,
                    "text": "Oltre 15 anni",
                    "feedback": null,
                    "order": 3
                  },
                  {
                    "id": 5,
                    "text": "Da 7 a 15 anni",
                    "feedback": null,
                    "order": 2
                  },
                  {
                    "id": 4,
                    "text": "Fino a 7",
                    "feedback": null,
                    "order": 1
                  }
                ],
                "type": {
                  "id": 6,
                  "slug": "LIST_SINGLE"
                }
              },
              {
                "id": 5,
                "text": "Quali sono le prospettive di sviluppo del settore di attivit\u00e0 dell\u2019impresa?",
                "attachment_url": null,
                "picture_url": null,
                "order": 4,
                "answers": [
                  {
                    "id": 9,
                    "text": "Crescita",
                    "feedback": null,
                    "order": 3
                  },
                  {
                    "id": 8,
                    "text": "Stallo",
                    "feedback": null,
                    "order": 2
                  },
                  {
                    "id": 7,
                    "text": "Recessione",
                    "feedback": null,
                    "order": 1
                  }
                ],
                "type": {
                  "id": 6,
                  "slug": "LIST_SINGLE"
                }
              }
            ],
            "order": null
          }
        ]
      }
    },
    submitted: false
  },
  mutations: {
    setSurvey(state, payload) {
      state.survey = payload;
    },
    setGoals(state, payload) {
      state.goals = payload;
    },
    setExtraFields(state, payload) {
      state.extraFields = payload;
    },
    setStartOfDescrizioneRete(state) {
      router.push('/descrizione-rete')
    },
    setGoalsSelected(state,payload) {
      state.goalsSelected = payload;
    },
    setCurrentModule(state, payload) {
      state.currentModule = payload;
    },
    setCurrentQuestion(state, payload) {
      state.currentQuestion = payload;
    },
    setQuestionAnswer(state, payload) {
      state.answers = payload;
    },
    setEndOfSurvey(state) {
      state.surveyIsDone = true;
      router.push('/revisione');
    },
    setStartOfSurvey(state, payload) {
      state.participant = payload;
      router.push('/definizione-obbiettivi-di-rete');
    },
    setStartOfModules(state,payload) {
      router.push('/questionario');
    },
    setParticipantId( state, payload ) {
      state.participantId = payload.id;
    },
    setSubmittedState(state) {
      state.submitted = true;
      router.push('/conferma');
    }
  },
  actions: {
    /**
     * Post data with participant infos to obtain participant ID
     * @param state
     * @param commit
     */
    async getParticipantId({state, commit}) {

      const data = {
        "first_name": state.participant.firstname,
        "last_name": state.participant.lastname,
        "email": state.participant.email,
        "extra_fields":{
          "cfPiva": state.participant.cfPiva,
        }
      }

      // todo: handle errors
      const response = await axios.post(`${process.env.VUE_APP_SURVEY_REST}/api/v1/participants`, data);

      const participant = response.data.data;

      commit('setParticipantId', participant);

    },
    /**
     * Retrieve survey from backend
     * @param state
     */
    async fetchSurvey(state) {
      const survey = await fetch(`${process.env.VUE_APP_SURVEY_REST}/api/v1/surveys/${process.env.VUE_APP_SURVEY_VERSION}`, {});
      const j = await survey.json();
      // todo: check if errors occurred
      state.commit('setSurvey', j.data);
    },
    /**
     * Retrieve Goals of Survey Revision
     * @param state
     */
    async fetchGoals(state) {
      const goals = await fetch(`${process.env.VUE_APP_SURVEY_REST}/api/v1/surveys/${process.env.VUE_APP_SURVEY_VERSION}/goals`, {});
      const g = await goals.json();
      // todo: check if errors occurred
      state.commit('setGoals', g.data);
    },
    /**
     *
     * @param commit
     * @param data
     */
    setGoalsSelected({commit}, data) {
      commit('setGoalsSelected', data);
    },
    /**
     * Set current module to be displayed
     * @param state
     * @param commit
     * @param data
     */
    setCurrentModule({state, commit}, data) {
      commit('setCurrentModule', data)
    },
    /**
     * Change current question, if position is > 0 go forward, else go backward
     * @param commit
     * @param getters
     * @param position
     */
    setCurrentQuestion({commit, getters}, position) {
      const questions = getters.getCurrentModule.questions;
      if(position > 0) {
        const nextQuestionId = questions[getters.getCurrentQuestionPosition].id;
        commit('setCurrentQuestion', nextQuestionId)
      } else {
        const nextQuestionId = questions[getters.getCurrentQuestionPosition - 2].id;
        commit('setCurrentQuestion', nextQuestionId)
      }
    },
    /**
     * Metodo di salvataggio del risultato nello state
     * @param commit
     * @param state
     * @param payload
     */
    setQuestionAnswer({commit, state}, payload) {

      if(!state.answers.length)
        commit('setQuestionAnswer', [{
          questionId: payload.questionId,
          value: payload.value,
          answerText: payload.answerText,
          questionType: payload.questionType }])

      const indexOfAnswer = state.answers.findIndex( (answer: {questionId: number}) => answer.questionId === payload.questionId )

      // todo: better define type
      const answers: Array<any> = state.answers

      if(indexOfAnswer == -1) {
        const newAnswers = answers.concat({
          questionId: payload.questionId,
          value: payload.value,
          answerText: payload.answerText,
          questionType: payload.questionType
        })
        commit('setQuestionAnswer', newAnswers)
      }
      else {
        answers[indexOfAnswer] = payload;
        commit('setQuestionAnswer', answers)
      }},
    /**
     * Metodo per procedere tra i moduli o settare la fine della survey
     * @param commit
     * @param getters
     * @param payload
     */
    setNextModule({commit, getters}, payload) {
      const currentModule = getters.getCurrentModule;
      const currentModulePos = getters.getAllModules.findIndex((x: { id: any }) => x.id === currentModule.id );

      if( getters.getAllModules[currentModulePos + 1] === undefined )
        commit('setEndOfSurvey');
      else {
        commit('setCurrentModule', getters.getAllModules[(currentModulePos + 1)].id)
        commit('setCurrentQuestion', getters.getAllModules[(currentModulePos + 1)].questions[0].id)
      }
    },
    /**
     * Setto gli extraFields e avvio la Survey
     * @param commit
     * @param dispatch
     * @param payload
     */
    setExtraFields({commit, dispatch}, payload) {
      commit('setExtraFields', payload);
      dispatch('startModules');
    },
    /**
     * Avvio il processo di accreditamento
     * @param commit
     * @param dispatch
     * @param payload
     */
    startSurvey({commit, dispatch}, payload) {
      commit('setStartOfSurvey', payload)
      dispatch('getParticipantId');
    },
    /**
     * Inizio le domande della survey attraverso i moduli
     * @param commit
     */
    startModules({commit}) {
      commit('setStartOfModules');
    },

    startDescrizioneRete({commit}) {
      commit('setStartOfDescrizioneRete')
    },
    /**
     * End of survey
     * @param getters
     * @param commit
     */
    async submitData({getters, commit}) {
      try {
        await axios.post(`${process.env.VUE_APP_SURVEY_REST}/api/v1/surveys/${process.env.VUE_APP_SURVEY_VERSION}/submissions`, getters.getSubmissionData );
        commit('setSubmittedState');
      } catch (e) {
        // todo: handle error
        console.log('Errore durante la submission dei dati verso il backend')
        console.log(e);
      }


    }
  },
  getters: {
    getAnswers: state => state.answers,
    getAllModules: state => state.survey.current_revision.modules,
    getGoals: state => state.goals,
    getCurrentModule: (state, getters) => getters.getAllModules.find((x: { id: any }) => x.id === state.currentModule),
    getCurrentQuestion: function(state, getters) {
      if(state.currentQuestion === 0)
        return undefined;
      const questions = getters.getCurrentModule.questions;
      return questions.find( (current: { id: number }) => current.id === state.currentQuestion )
    },
    getCurrentQuestionType: function(state, getters) {
      return getters.getCurrentQuestion.type.slug;
    },
    getCurrentQuestionPosition: function(state, getters) {
      return getters.getCurrentModule.questions.findIndex( (current: {id: number}) => current.id === state.currentQuestion ) + 1
    },
    getCurrentQuestionLength: function(state, getters) {
      return getters.getCurrentModule.questions.length;
    },
    getCurrentQuestionIsLast: function(state, getters) {
      return getters.getCurrentQuestionLength === getters.getCurrentQuestionPosition;
    },
    getCurrentQuestionAnswer: function(state, getters) {
      const questionId = getters.getCurrentQuestion.id;
      const answer = state.answers.find((x: { questionId: number }) => x.questionId === questionId )

      return answer ? answer : '';
    },
    /**
     * Ritorna un oggetto con lo schema atteso dal backend per la submission delle risposte
     * @param state
     */
    getSubmissionData: function(state) {
      return {
        // @ts-ignore
        answers: Object.fromEntries( (new Map(state.answers.map(a => [a.questionId, a.value])))),
        'participant_id': state.participantId,
        'extra_fields': state.extraFields,
        // @ts-ignore
        goals: Object.fromEntries( new Map( state.goalsSelected.map(goal => [goal.id, {'primary': goal.primary}]) ) )
      }
    }
  },
  modules: {
  }
})
