<template>
    <div class="content-left-wrapper">
        <a href="/" id="logo"><img src="../assets/logo_negativo.png" alt="" width="175"></a>
        <SocialIcons></SocialIcons>
        <div>
            <figure><img v-bind:src="image" alt="" class="img-fluid"></figure>
            <h2 v-html="currentModule.title"></h2>
            <p v-html="currentModule.description"></p>
            <a v-if="currentModule.ctaLink" v-bind:href="currentModule.ctaLink" class="btn_1 rounded">{{ currentModule.ctaText }}</a>
        </div>
        <div class="copy">© 2020 RetImpresa Reference</div>
    </div>
    <!-- /content-left-wrapper -->
</template>

<script>
    import SocialIcons from "@/components/SocialIcons";
  export default {
      name: "SideDescriptionArea",
    components: {
        SocialIcons
    },
      computed: {
        currentModule() {
          return this.$store.getters.getCurrentModule;
        },
        image() {
          const imageUrl = this.$store.getters.getCurrentModule.picture_url;
          return imageUrl ? imageUrl : '';
        }
      }
  }
</script>

<style scoped>

</style>
