<!--
Example:
<Checkboxes
        v-for="data in questions"
        v-bind:value="data.value"
        name="question_1[]"
        v-bind:label="data.label"
        v-bind:key="data.value"
        v-bind:checkedstate="data.checked"
></Checkboxes>
-->

<template>
    <div class="form-group">
        <label class="container_check version_2">{{ label }}
            <input type="checkbox" v-bind:name="name" v-bind:value="value" v-bind:class="classes" v-bind:checked="checkedstate">
            <span class="checkmark"></span>
        </label>
    </div>
</template>

<script>
  export default {
    name: "Checkbox",
    props: {
      label: String,
      name: String,
      value: String,
      classes: String,
      checkedstate: Boolean
    }
  }
</script>

<style scoped>

</style>
