<template>
    <div id="social">
        <ul>
            <li><a target="_blank" href="https://www.retimpresa.it"><i class="social-icon icon-globe"></i></a></li>
            <li><a target="_blank" href="https://www.facebook.com/RetImpresa/"><i class="social-icon icon-facebook"></i></a></li>
            <li><a target="_blank" href="https://www.linkedin.com/company/retimpresa"><i class="social-icon icon-linkedin"></i></a></li>
        </ul>
    </div>
</template>

<script>
  export default {
    name: "SocialIcons"
  }
</script>

<style scoped>

</style>
