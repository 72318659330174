import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/definizione-obbiettivi-di-rete',
    name: 'Obbiettivi di Rete',
    component: () => import('../views/Goals.vue')
  },
  {
    path: '/descrizione-rete',
    name: 'Descrizione Rete d\'Impresa',
    component: () => import('../views/DescrizioneRete.vue')
  },
  {
    path: '/questionario',
    name: 'Questionario',
    component: () => import('../views/Survey.vue')
  },
  {
    path: '/revisione',
    name: 'Revisione delle risposte',
    component: () => import('../views/EndSurvey.vue')
  },
  {
    path: '/conferma',
    name: 'Conferma Invio Dati',
    component: () => import('../views/Confirm.vue')
  },
  {
    path: '/report',
    name: 'Report - RetImpresa Reference',
    component: () => import('../views/Report.vue')
  },
  {
    path: '/embed/:submission_id',
    name: 'embed-benchmark',
    component: () => import('../views/Embed.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
